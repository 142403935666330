import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"



const Aromapflege = ({data}) => {

  // Single Image Data
  const allImages = data.allImages.edges
  const title = "Aromapflege"

  return (
    <Layout title={title} slogan="Der Duft ist ein mächtiger Zauberer, der uns über Tausend von Meilen hinweg trägt, über all die Jahre, die wir gelebt haben." headerImage={data.allImages.edges[0].node.childImageSharp.fluid}>
      <div>
        <ImageGalleryWithLightbox className="grid grid-3" images={allImages} />
        <div style={{marginTop: '1rem'}}>
          <h4>Geschichte der Aromapflege</h4>
          <p>
            Die Geschichte der Duftkultur reicht mehr als 5000 Jahre zurück. Gerüche spielten im Leben der Ägypter eine wichtige Rolle. Schon 3000 v. Christus unter Ramsens lll begann der Handel mit aromatischen Präparaten. In unserer Region begann die Geschichte im Jahr 800 mit Karl dem Grossen. Er erliess das Gesetz, dass in Kloster- und Bauerngärten Heilpflanzen angesetzt werden sollen. Der wohl bekannteste Vertreter der modernen Aromapflege ist Dr. Jean Valnet, der im 2. Weltkrieg Bemerkenswertes für die Kriegsverletzten leistete und sein ganzes Leben den ätherischen Ölen widmete.
          </p>
          <h4>Ätherische Öle</h4>
          <p>
            Sind Gemische biochemischer Verbindungen von Pflanzen. Man gewinnt sie aus Blüten, Blättern, Kräutern, Nadeln, Samen, Früchten, Wurzeln und Hölzern. Diese werden mit Wasserdestillation gewonnen (z.B. Rosen, Lavendel, Orangenblüten) oder durch Kaltpressung (z.B. Schalen von Orangen, Bergamotte, Zitrone) oder durch Auszugsverfahren (z.B. Jasmin, Mimose etc.).
          </p>
          <h4>Anwendung</h4>
          <p>
            <ol>
              <li>Förderung des Wohlbefindens.</li>
              <li>Unterstützung von Heilprozessen, körperlich und psychisch (z.B. bei Schlafstörungen, Erschöpfung, Schmerzen, Erkältungen).</li>
            </ol>
            Sind Gemische biochemischer Verbindungen von Pflanzen. Man gewinnt sie aus Blüten, Blättern, Kräutern, Nadeln, Samen, Früchten, Wurzeln und Hölzern. Diese werden mit Wasserdestillation gewonnen (z.B. Rosen, Lavendel, Orangenblüten) oder durch Kaltpressung (z.B. Schalen von Orangen, Bergamotte, Zitrone) oder durch Auszugsverfahren (z.B. Jasmin, Mimose etc.).
          </p>
          <p>
            Wohl sehr bekannt ist z.B. Lavendel, ein Multitalent unter den ätherischen Ölen:
            <ul>
              <li>Körperliche Wirkung: entzündungshemmend, antibakteriell, antiseptisch, durchblutungsfördernd, juckreizstillend, krampflösend, schmerzstillend.</li>
              <li>Psychisch: angstlösend, aufbauend, beruhigend.</li>
            </ul>
            Falls der Lavendel nicht zusagt ist das Pendant Rosengeranie. So gibt es für jede Frau / Mann das passende ätherische Öl.
            Für Auskünfte und Fragen stehen wir unseren Bewohnerinnen und Bewohnern jederzeit zur Verfügung.Andrea Lauber
          </p>
        </div>

      </div>
    </Layout>
  ) 
}

export default Aromapflege

export const pageQuery = graphql`
  query {
    allImages: allFile(filter: {relativeDirectory: {eq: "angebot/aromapflege"}}, sort: {order: ASC, fields: name}) {
			edges {
				node {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1600) {
							...GatsbyImageSharpFluid_withWebp
						}
					},
					name
				}
			}
		}
  }
`